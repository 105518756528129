@import '~antd/dist/antd.css';
body {
  background: grey;
}

#root {
  height: 100%;
}

.ant-layout {
  min-height: 100%;
}

.left {
  float: left;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  float: right;
}
