.plusIcon {
    /* background   : #1890FF;
  color        : #fff;
  font-size    : 20px;
  padding      : 5px;
  border-radius: 20px; */
    margin-bottom: 30px;
}

.btn {
    text-align: right;
}

.btn .submit {
    margin-left: 8px;
}

.cardCover {
    margin-bottom: 20px;
}

.cardCover .ant-card-cover {
    height: 200px;
    overflow: auto;
}

.selectBook {
    padding: 20px 0;
    text-align: left;
}