.login-bg {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: auto;
    justify-content: center;
    align-items: center;
}

.bg-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(../../../assets/slice0.jpg);
    background-size: cover;
    background-position: center bottom;
}

.bg-box::before {
    content: '';
    display: block;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    height: 100%;
    width: 100%;
}

.form-box {
    background: #fff;
    padding: 50px;
}

.login-title {
    text-align: center;
    margin-bottom: 20px !important;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.bottomBtn {
    margin-bottom: 0;
}

.register {
    text-align: center;
}

.register a {
    color: #999;
}
